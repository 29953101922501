export default {
  name: "AddressModal",
  props: {
    showModal: Boolean,
    method: Function
  },
  data() {
    return {
      propertyAddress: "",
      isAddress: false
    };
  },
  methods: {
    onAddressSubmit() {
      if (this.propertyAddress === "") {
        this.isAddress = true;
      } else {
        this.method(this.propertyAddress);
      }
    }
  }
};
export default {
  name: "ResultView",
  props: {
    criteriaBasedContent: Array,
    overallRating: String,
    propertyAddress: String,
    method: Function
  },
  methods: {
    onPreviousClick() {
      this.method();
    },
    onShareClick() {
      /***** IMPORTANT: KEEPING THIS CODE IF WE WANT TO CAPTURE IMAGE IN FUTURE **********/
      // const captureElement = document.querySelector("#capture");
      // const html2canvas = require("html2canvas");
      // html2canvas(captureElement)
      //   .then((canvas) => {
      //     canvas.style.display = "none";
      //     document.body.appendChild(canvas);
      //     return canvas;
      //   })
      //   .then((canvas) => {
      //     const capturedImage = canvas.toDataURL("image/png");

      //     const imageName = `Property-Rating-${Date.now()}`;
      //     // Create Temporary Tag to download image
      //     const a = document.createElement("a");
      //     a.setAttribute("download", imageName);
      //     a.setAttribute("href", capturedImage);
      //     a.click();
      //     canvas.remove();
      // });
      /* ****************** ********************* *************** */
      const subject = `Property Rating of ${this.propertyAddress}`;
      let body = "This is the Rating Summary of property \n";
      for (var i = 0; i < this.criteriaBasedContent.length; i++) {
        body = body + `${this.criteriaBasedContent[i].title}: ${this.criteriaBasedContent[i].rating} \n`;
      }
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.href = "mailto:?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
      } else {
        //This open Compose window only in new Tab
        window.open("https://mail.google.com/mail/u/0/?tf=cm&su=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body));

        //This opens Gmail Compose View 
        // window.open("https://mail.google.com/mail/u/0/#inbox?compose=new&su=SUBJECT", "_blank");
      }
    },

    sendEmailToChris() {
      const subject = "Could you give me a hand?";
      let body = "Hi Chris \n\n My name is ... \n\n I've just been using your HiReturn Filter to assess a property. And have a couple of things I need your help with. \n\nCould you please phone me on ... \n\nRegards";
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.href = "mailto:chris.lang@his-best.biz?subject=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body);
      } else {
        //This open Compose window only in new Tab
        window.open("https://mail.google.com/mail/u/0/?tf=cm&to=chris.lang@his-best.biz&su=" + encodeURIComponent(subject) + "&body=" + encodeURIComponent(body));

        //This opens Gmail Compose View 
        // window.open("https://mail.google.com/mail/u/0/#inbox?compose=new&su=SUBJECT", "_blank");
      }
    }
  }
};
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import "/src/assets/main.css";
export default {
  __name: 'App',
  setup(__props) {
    // import { RouterLink, RouterView } from "vue-router";

    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};
//App Rounting
import { createRouter, createWebHistory,  } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import RatingView from '@/views/RatingView.vue'
import RatingInfo from '@/views/RatingInfo.vue'
import VideoView from '@/views/VideoView.vue'
import ResultView from '@/views/ResultView.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/video',
    name: 'Video',
    component: VideoView
  },
  {
    path: '/rating',
    name: 'Rating',
    component: RatingView
  },
  {
    path: '/info',
    name: 'Info',
    component: RatingInfo
  },
  {
    path: '/result',
    name: 'Result',
    component: ResultView
  }
]
const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router

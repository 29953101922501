import { ref } from "vue";
import ResultView from "./ResultView.vue";
import AddressModal from "../components/AddressModal.vue";
export default {
  components: {
    ResultView,
    AddressModal
  },
  setup() {
    const value = ref(0);
    return {
      value
    };
  },
  data() {
    return {
      showModal: true,
      propertyAddress: "",
      currentQuestionnaire: 0,
      overallRating: 0,
      criteriaBasedContent: [{
        title: "Tenant Calibre",
        rating: 0,
        weight: 1,
        c1: `<span class='font-semibold'>To Rate close to a 10 ...</span>`,
        c2: `<span class="font-semibold">Paying $500,000 to $1 million:</span>`,
        c3: "Good tenant, or solid security deposit",
        c4: `<span class="font-semibold">Paying $1 million to $2 million:</span>`,
        c5: "Proven Corporate with security deposit",
        c6: `<span class="font-semibold">Paying $2 million+:</span>`,
        c7: "Ideally a Public company or a large Private, with 12-month bank guarantee"
      }, {
        title: "Lease Term",
        rating: 0,
        weight: 1,
        c1: `A <span class="font-semibold">10 Rating</span> quickly falls away ... the shorter the remaining Lease Term.`,
        c2: `<span class="font-semibold">Paying $500,000 to $1 million:</span>`,
        c3: "Term of 3 to 4 years",
        c4: `<span class="font-semibold">Paying $1 million to $2 million:</span>`,
        c5: "Term of 4 to 6 years",
        c6: `<span class="font-semibold">Paying $2 million+:</span>`,
        c7: "Term of 6 to 10 years"
      }, {
        title: "Recent Construction",
        rating: 0,
        weight: 1,
        c1: `<span class="font-semibold">This one is easy, because ...</span>`,
        c2: "Modern construction requires little maintenance, attracts good tenants and provide you with tax benefits.",
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Flexible Design",
        rating: 0,
        weight: 0.9,
        c1: `<span class="font-semibold">What you need to consider here is when your tenant leaves ...</span>`,
        c2: "How easily could you re-let the space to one or more tenants &#8211; without the need for huge passageways?",
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Lease Structure",
        rating: 0,
        weight: 0.9,
        c1: `<span class="font-semibold">Ask yourself ...</span>`,
        c2: "Are there Automatic increases of at least 3% per annum?",
        c3: "Does the Tenant pay the outgoings?",
        c4: "Is there a security deposit (or bank guarantee) for at least 10% of the annual rental?",
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Absence of Competition",
        rating: 0,
        weight: 0.9,
        c1: `<span class="font-semibold">Ask yourself ...</span>`,
        c2: "Could this property be easily replicated (ie: are there vacant sites or very old buildings) nearby?",
        c3: "Are there already other Vacancies in the surrounding area?",
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Good Position",
        rating: 0,
        weight: 0.8,
        c1: `<span class="font-semibold">Rate based upon ...</span>`,
        c2: "Visibility of the building, Public Transport, Freeway access and proximity to similar uses nearby.",
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Emerging Trends",
        rating: 0,
        weight: 0.8,
        c1: `<span class="font-semibold">9-10: </span>Up-to-date with technology and environmental issues.`,
        c2: `<span class="font-semibold">7-8:</span> Could be easily upgraded.`,
        c3: `<span class="font-semibold">5-6:</span> Will be hard to upgrade.`,
        c4: `<span class="font-semibold">3-4:</span> Could not be changed.`,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Passing Yield",
        rating: 0,
        weight: 0.7,
        c1: `<span class="font-semibold">9-10:</span> Below Market, but with an approaching Market Review.`,
        c2: `<span class="font-semibold">7-8:</span> Currently at Market rental.`,
        c3: `<span class="font-semibold">5-6:</span> Already above Market level.`,
        c4: `<span class="font-semibold">3-4:</span> Below Market, without any Market review soon.`,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Council Zoning",
        rating: 0,
        weight: 0.7,
        c1: `<span class="font-semibold">10:</span> Allows for an improved Use.`,
        c2: `<span class="font-semibold">8-9:</span> Currently appropriate.`,
        c3: `<span class="font-semibold">2-4:</span> Restrictive`,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Title Options",
        rating: 0,
        weight: 0.7,
        c1: `<span class="font-semibold">Ask yourself ...</span>`,
        c2: "How easy would it be to further subdivide the Property?",
        c3: null,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }, {
        title: "Vendor Motivation",
        rating: 0,
        weight: 0.6,
        c1: `<span class="font-semibold">9-10:</span> Vendors keen to sell.`,
        c2: `<span class="font-semibold">7-8:</span> Are Genuine sellers.`,
        c3: `<span class="font-semibold">1-4:</span> Testing the market`,
        c4: null,
        c5: null,
        c6: null,
        c7: null
      }]
    };
  },
  methods: {
    onChangeRate(value) {
      this.overallRating = 0;
      console.log(this.currentQuestionnaire, "Info");
      this.criteriaBasedContent[this.currentQuestionnaire].rating = value;
      this.criteriaBasedContent.forEach(element => {
        this.overallRating = this.overallRating + element.rating * element.weight;
      });
    },
    onAddressSubmit(address) {
      this.showModal = false;
      this.propertyAddress = address;
    },
    onPreviousClick() {
      this.currentQuestionnaire = this.currentQuestionnaire - 1;
    }
  }
};